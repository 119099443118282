import React, { useState, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl";
import tw from "twin.macro";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import * as Yup from "yup";
import Select from "react-select";
import { navigate } from "gatsby";
import { Transition } from "@tailwindui/react";
import { ApolloProvider } from "@apollo/client";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { states, formatPhoneNumber, getDate } from "../components/app/helpers";
import Toggle from "../components/inputs/toggle";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { handleLogin, isLoggedIn } from "../components/app/services/auth";
import { useRegisterUserMutation } from "../components/app/generated/graphql";
import client from "../gatsby-theme-apollo/client";

const RegisterPage: React.FC = () => {
  const lang = useIntl();

  const ValidationSchema = useMemo(() => {
    return Yup.object().shape({
      email: Yup.string()
        .email(lang.formatMessage({ id: "error_invalid_email" }))
        .required(lang.formatMessage({ id: "required" })),
      password: Yup.string()
        .min(2, lang.formatMessage({ id: "error_too_short" }))
        .max(50, lang.formatMessage({ id: "error_too_long" }))
        .required(lang.formatMessage({ id: "required" })),
      confirmPassword: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          lang.formatMessage({ id: "error_password_matching" })
        )
        .required(lang.formatMessage({ id: "required" })),
      firstName: Yup.string().required(lang.formatMessage({ id: "required" })),
      lastName: Yup.string().required(lang.formatMessage({ id: "required" })),
      phoneNumber: Yup.string().required(
        lang.formatMessage({ id: "required" })
      ),
      // .min(14, "Phone number is not valid"),
      // .max(14, "Phone number is not valid"),
      // .matches(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/, 'Phone number is not valid'),
      address_1: Yup.string().required(lang.formatMessage({ id: "required" })),
      city: Yup.string().required(lang.formatMessage({ id: "required" })),
      state: Yup.string().required(lang.formatMessage({ id: "required" })),
      zipcode: Yup.string().required(lang.formatMessage({ id: "required" })),
      tos: Yup.boolean()
        .test(
          "tos",
          lang.formatMessage({ id: "error_tos" }),
          value => value === true
        )
        .required(lang.formatMessage({ id: "required" })),
    });
  }, [lang]);

  const [selectedDate, handleDateChange] = useState(
    getDate(moment().format(), lang.locale)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [registerUser, { data, loading, error }] = useRegisterUserMutation();

  if (isLoggedIn()) {
    navigate(`/app`);
  }

  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Layout>
          <SEO
            title="Sign Up"
            description="Sign Up | Register a new GivFast account."
          />
          <Transition
          show={showErrorMessage}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div>
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm leading-5 font-medium text-red-800">
                    <FormattedMessage id="oops" />
                  </h3>
                  <div className="mt-2 text-sm leading-5 text-red-700">
                    <ul className="list-disc pl-5">
                      <li>
                        {errorMessage}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      type="button"
                      onClick={() => setShowErrorMessage(false)}
                      className="inline-flex rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:bg-red-100 transition ease-in-out duration-150"
                      aria-label="Dismiss"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
          <div className="py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
            <div className="relative max-w-xl mx-auto">
              <div className="text-center">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                  <FormattedMessage id="label_register" />
                </h2>
                {/* <p className="mt-4 text-lg leading-6 text-gray-500">
                Nullam risus blandit ac aliquam justo ipsum. Quam mauris
                volutpat massa dictumst amet. Sapien tortor lacus arcu.
                </p> */}
              </div>
              <div className="mt-12">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    address_1: "",
                    address_2: "",
                    city: "",
                    state: "",
                    zipcode: "",
                    tos: false,
                  }}
                  validationSchema={ValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      const response = await registerUser({
                        variables: {
                          username: values.email,
                          password: values.password,
                          clientId: process.env.GIVFAST_API_CLIENT_ID,
                          data: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            dob: selectedDate.toISOString(),
                            acceptedTerms: values.tos,
                            address: {
                              city: values.city,
                              street1: values.address_1,
                              street2: values.address_2,
                              state: values.state,
                              zipcode: values.zipcode,
                              phone: formatPhoneNumber(values.phoneNumber),
                            },
                          },
                        },
                      });

                      actions.setSubmitting(false);

                      if (
                        response &&
                        response.data &&
                        response.data.registerUser
                      ) {
                        const { token, user } = response.data.registerUser;
                        handleLogin({ user, token });
                        navigate(`/app`);
                      } else {
                        console.warn("ERROR:", response);
                        setShowErrorMessage(true);
                        setErrorMessage(response.errors.toString());
                      }
                    } catch (error) {
                      console.error(error);
                      setErrorMessage(error.message);
                      setShowErrorMessage(true);
                      actions.setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting, errors, setFieldValue, values }) => (
                    <Form className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_email" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="email" type="email" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="email"
                            name="email"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.email
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder="you@example.com"
                            autoComplete={"email"}
                          />
                        </div>
                        <ErrorMessage name="email">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="email-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_password" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input type="password" id="password" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="password"
                            name="password"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.password
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            autoComplete={"new-password"}
                          />
                        </div>
                        <ErrorMessage name="password">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="password-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="confirmPassword"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_confirm_password" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <Field
                            type="password"
                            name="confirmPassword"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.confirmPassword
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            autoComplete={"new-password"}
                          />
                        </div>
                        <ErrorMessage name="confirmPassword">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="confirmPassword-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_f_name" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="firstName" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="firstName"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.firstName
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage name="firstName">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="firstName-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_l_name" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="last_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="lastName"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.lastName
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage name="lastName">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="lastName-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="date_of_birth"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_dob" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <DatePicker
                            format={"MMMM DD, yyyy"}
                            value={selectedDate}
                            onChange={handleDateChange}
                            open={isOpen}
                            onOpen={() => setIsOpen(true)}
                            onClose={() => setIsOpen(false)}
                            TextFieldComponent={props => {
                              return (
                                <button
                                  onClick={() => setIsOpen(true)}
                                  type="button"
                                  id="date_of_birth"
                                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-left"
                                >
                                  {props.value}
                                </button>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="phoneNumber"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_phone" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="phone_number" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" placeholder="(555) 987-6543" /> */}
                          {/* <Field
                        type="text"
                        name="phoneNumber"
                        className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                          errors.phoneNumber
                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                            : ""
                          }`}
                        placeholder=""
                      /> */}
                          <Field name="phoneNumber">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              const formattedValue = formatPhoneNumber(
                                field.value
                              );

                              if (formattedValue) {
                                field.value = formattedValue;
                              }

                              return (
                                <>
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm sm:leading-5">
                                      + 1
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 pl-10 ${
                                      errors.phoneNumber
                                        ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                        : ""
                                    }`}
                                    {...field}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </div>
                        <ErrorMessage name="phoneNumber">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="phoneNumber-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="address_1"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_street_1" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="address_1" type="text" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="address_1"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.address_1
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage name="address_1">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="address_1-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="address_2"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_street_2" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="address_2" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="address_2"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150`}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_city" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="city" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="city"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.city
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage name="city">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="city-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_state" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <select id="state" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 form-select">
                        
                      </select> */}
                          <Select
                            options={states}
                            onChange={option =>
                              setFieldValue("state", option.value, true)
                            }
                            styles={{
                              valueContainer: (provided, state) => {
                                return { ...provided, padding: "0.5rem" };
                              },
                            }}
                          />
                        </div>
                        <ErrorMessage name="state">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="state-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label
                          htmlFor="zipcode"
                          className="block text-sm font-medium leading-5 text-gray-700"
                        >
                          <FormattedMessage id="label_zipcode" />
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          {/* <input id="zipcode" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                          <Field
                            type="text"
                            name="zipcode"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.zipcode
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                            placeholder=""
                          />
                        </div>
                        <ErrorMessage name="zipcode">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="zipcode-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div></div>
                      <div className="sm:col-span-2">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <Toggle
                              isOn={values.tos}
                              onClick={() =>
                                setFieldValue("tos", !values.tos, true)
                              }
                              color={tw`bg-green-600`}
                            />
                          </div>
                          <div className="ml-3">
                            <p className="text-base leading-6 text-gray-500">
                              <FormattedMessage id="label_agree" />
                              {` `}
                              <Link
                                target="blank"
                                to="/terms-of-use"
                                className="font-medium text-gray-700 underline"
                              >
                                <FormattedMessage id="nav_link_terms" />
                              </Link>
                              {` `}and{` `}
                              <Link
                                target="blank"
                                to="/privacy-policy"
                                className="font-medium text-gray-700 underline"
                              >
                                <FormattedMessage id="nav_link_privacy" />
                              </Link>
                              .
                            </p>
                          </div>
                        </div>
                        <ErrorMessage name="tos">
                          {(msg: string): React.ReactNode => (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="tos-error"
                            >
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="sm:col-span-2">
                        <span className="w-full inline-flex rounded-md shadow-sm">
                          <button
                            type="submit"
                            className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-indigo active:bg-teal-700 transition ease-in-out duration-150"
                            disabled={!values.tos || isSubmitting}
                            css={[
                              !values.tos && tw`opacity-50 cursor-not-allowed`,
                            ]}
                          >
                            {isSubmitting && (
                              <svg
                                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            )}
                            <FormattedMessage id="label_register" />
                          </button>
                        </span>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Layout>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
};

export default RegisterPage;
